import React, { useState, useEffect } from 'react';
import { 
  RevisionExcerpt, 
  Revision,
  fetchRevisionsExcerpt, 
  fetchRevision,
  revertToRevision 
} from '../../../services/api';
import './VersionHistory.css';
import Modal from '../../../components/Modal';

interface VersionHistoryProps {
  sessionToken: string;
  festivalId: string;
  versionId: string;
  onDataUpdate: (newData: any) => void;
}

const VersionHistory: React.FC<VersionHistoryProps> = ({
  sessionToken,
  festivalId,
  versionId,
  onDataUpdate,
}) => {
  const [revisions, setRevisions] = useState<RevisionExcerpt[]>([]);
  const [selectedRevision, setSelectedRevision] = useState<RevisionExcerpt | null>(null);
  const [fullRevision, setFullRevision] = useState<Revision | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDiff, setIsLoadingDiff] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showDiffModal, setShowDiffModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    const loadRevisions = async () => {
      try {
        setIsLoading(true);
        const fetchedRevisions = await fetchRevisionsExcerpt(sessionToken, festivalId, versionId);
        setRevisions(fetchedRevisions);
      } catch (err) {
        console.error('Error fetching revisions:', err);
        setError(err instanceof Error ? err.message : 'Failed to load revision history');
      } finally {
        setIsLoading(false);
      }
    };

    loadRevisions();
  }, [sessionToken, festivalId, versionId]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('default', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  const handleRevertClick = async (revision: RevisionExcerpt) => {
    setSelectedRevision(revision);
    setShowDiffModal(true);
    setIsLoadingDiff(true);
    
    try {
      const fullRevisionData = await fetchRevision(
        sessionToken,
        festivalId,
        revision.uuid
      );
      setFullRevision(fullRevisionData);
    } catch (err) {
      console.error('Error fetching revision details:', err);
      setError(err instanceof Error ? err.message : 'Failed to load revision details');
    } finally {
      setIsLoadingDiff(false);
    }
  };

  const handleConfirmRevert = () => {
    setShowDiffModal(false);
    setShowConfirmModal(true);
  };

  const handleFinalRevert = async () => {
    if (!selectedRevision) return;

    try {
      const revertedData = await revertToRevision(
        sessionToken,
        festivalId,
        versionId,
        selectedRevision.uuid
      );
      
      onDataUpdate(revertedData);
      
      setShowConfirmModal(false);
      setSelectedRevision(null);
    } catch (err) {
      console.error('Error reverting to revision:', err);
      setError(err instanceof Error ? err.message : 'Failed to revert to revision');
    }
  };

  const formatDiff = (diffString: string): JSX.Element => {
    try {
      const changes = JSON.parse(diffString);
      return (
        <pre className="diff-raw">
          {JSON.stringify(changes, null, 2)}
        </pre>
      );
    } catch (e) {
      console.error('Error parsing diff:', e);
      return (
        <pre className="diff-raw">
          {diffString}
        </pre>
      );
    }
  };

  return (
    <div className="version-history">
      <h2>History</h2>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : revisions.length === 0 ? (
        <div className="empty-state">
          <p>No revision history available.</p>
        </div>
      ) : (
        <>
          <ul className="revision-list">
            {revisions.map((revision) => (
              <li key={revision.uuid} className="revision-item">
                <div className="revision-row">
                  <div className="revision-user-info">
                    <span className="user-email">{revision.user_email}</span>
                    <span className="revision-date">{formatDate(revision.date)}</span>
                  </div>
                  <button
                    onClick={() => handleRevertClick(revision)}
                    className="revert-button"
                  >
                    See changes
                  </button>
                </div>
              </li>
            ))}
          </ul>

          <Modal
            isOpen={showDiffModal}
            onClose={() => {
              setShowDiffModal(false);
              setFullRevision(null);
            }}
          >
            <h3>Version Changes</h3>
            <div className="diff-content">
              {isLoadingDiff ? (
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              ) : fullRevision ? (
                formatDiff(fullRevision.diff)
              ) : (
                <div className="diff-error">Failed to load revision details</div>
              )}
            </div>
          </Modal>

          <Modal
            isOpen={showConfirmModal}
            onClose={() => {
              setShowConfirmModal(false);
              setSelectedRevision(null);
            }}
          >
            <h3>Confirm Revert</h3>
            <p>This will replace current data with previous content.</p>
            <div className="modal-actions">
              <button 
                onClick={() => setShowConfirmModal(false)}
                className="secondary-button"
              >
                Cancel
              </button>
              <button 
                onClick={handleFinalRevert}
                className="primary-button"
              >
                OK
              </button>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default VersionHistory; 