import React, { useEffect, useState, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MapOverlay.css';

interface MapOverlayProps {
  imageUrl: string;
  onBoundsChanged: (bounds: {
    north: number;
    south: number;
    east: number;
    west: number;
  }) => void;
  initialBounds: {
    top_left: { latitude: number; longitude: number };
    bottom_right: { latitude: number; longitude: number };
  };
}

interface GenericBounds {
  north: number;
  south: number;
  east: number;
  west: number;
}

// Custom zoom control component
const ZoomControl: React.FC<{
  zoom: number;
  onZoomChange: (zoom: number) => void;
}> = ({ zoom, onZoomChange }) => {
  const handleZoomIn = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (zoom < 22) {
      const newZoom = Math.min(22, parseFloat((zoom + 0.1).toFixed(1)));
      onZoomChange(newZoom);
    }
  };

  const handleZoomOut = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (zoom > 1) {
      const newZoom = Math.max(1, parseFloat((zoom - 0.1).toFixed(1)));
      onZoomChange(newZoom);
    }
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newZoom = parseFloat(parseFloat(e.target.value).toFixed(1));
    if (!isNaN(newZoom)) {
      onZoomChange(newZoom);
    }
  };

  return (
    <div className="custom-zoom-control">
      <div className="control-title">Zoom Level</div>
      <div className="control-content">
        <button 
          type="button" 
          onClick={handleZoomOut}
          disabled={zoom <= 1}
        >-</button>
        <input
          type="range"
          min="1"
          max="22"
          step="0.1"
          value={zoom}
          onChange={handleSliderChange}
        />
        <button 
          type="button" 
          onClick={handleZoomIn}
          disabled={zoom >= 22}
        >+</button>
        <div className="zoom-value">{zoom.toFixed(1)}</div>
      </div>
    </div>
  );
};

// Add new TransparencyControl component
const TransparencyControl: React.FC<{
  opacity: number;
  onOpacityChange: (opacity: number) => void;
}> = ({ opacity, onOpacityChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOpacity = parseFloat(e.target.value);
    onOpacityChange(newOpacity);
  };

  return (
    <div className="custom-transparency-control">
      <div className="control-title">Map Transparency</div>
      <div className="control-content">
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          value={opacity}
          onChange={handleChange}
        />
        <span className="opacity-value">{Math.round(opacity * 100)}%</span>
      </div>
    </div>
  );
};

const MapOverlay: React.FC<MapOverlayProps> = ({ imageUrl, onBoundsChanged, initialBounds }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const [zoom, setZoom] = useState(15);
  const [opacity, setOpacity] = useState(0.7);
  const mapboxRef = useRef<mapboxgl.Map | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const mapDivRef = useRef<HTMLDivElement>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const bounds: GenericBounds = {
    north: Number(initialBounds.top_left.latitude),
    south: Number(initialBounds.bottom_right.latitude),
    east: Number(initialBounds.bottom_right.longitude),
    west: Number(initialBounds.top_left.longitude),
  };

  const center = {
    lat: (bounds.north + bounds.south) / 2,
    lng: (bounds.east + bounds.west) / 2,
  };

  // Image loading effect
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const containerHeight = containerRef.current.clientHeight;
        
        const imageAspectRatio = img.naturalWidth / img.naturalHeight;
        const containerAspectRatio = containerWidth / containerHeight;
        
        let width, height;
        
        if (imageAspectRatio > containerAspectRatio) {
          width = containerWidth;
          height = containerWidth / imageAspectRatio;
        } else {
          height = containerHeight;
          width = containerHeight * imageAspectRatio;
        }
        
        setDimensions({
          width: Math.floor(width),
          height: Math.floor(height)
        });
      }
      setIsLoading(false);
    };
    img.onerror = () => {
      console.error('Failed to load image');
      setIsLoading(false);
    };
    img.src = imageUrl;
  }, [imageUrl]);

  // Map initialization effect
  useEffect(() => {
    if (!mapDivRef.current || isLoading) return;

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';
    
    const map = new mapboxgl.Map({
      container: mapDivRef.current,
      style: 'mapbox://styles/mapbox/satellite-streets-v12',
      center: [center.lng, center.lat],
      zoom: zoom,
      minZoom: 1,
      maxZoom: 22,
    });

    map.on('load', () => {
      // First mark the map as loaded
      setIsMapLoading(false);
    });

    mapboxRef.current = map;

    return () => {
      if (mapboxRef.current) {
        mapboxRef.current.remove();
        mapboxRef.current = null;
      }
      setIsInitialized(false);
      setIsMapLoading(true);
    };
  }, [isLoading]);

  // Separate effect for initializing map position
  useEffect(() => {
    if (isMapLoading || !mapboxRef.current || isInitialized) return;

    const map = mapboxRef.current;

    requestAnimationFrame(() => {
      map.resize();
      
      map.fitBounds([
        [bounds.west, bounds.south],
        [bounds.east, bounds.north]
      ], {
        padding: 0,
        animate: false,
        offset: [0, 0],
        maxZoom: 18
      });

      map.once('moveend', () => {
        const layers = [
          'poi-label',
          'transit-station-label',
          'airport-label',
          'place-label',
          'building-label'
        ];

        layers.forEach(layer => {
          if (map.getLayer(layer)) {
            map.setLayoutProperty(layer, 'text-field', ['get', 'name']);
            map.setLayoutProperty(layer, 'text-size', 12);
            map.setLayoutProperty(layer, 'text-anchor', 'top');
            map.setLayoutProperty(layer, 'text-offset', [0, 0.5]);
            map.setLayoutProperty(layer, 'visibility', 'visible');
            map.setLayoutProperty(layer, 'icon-size', 1);
            map.setLayoutProperty(layer, 'icon-allow-overlap', true);

            map.setPaintProperty(layer, 'text-color', '#ffffff');
            map.setPaintProperty(layer, 'text-halo-color', '#000000');
            map.setPaintProperty(layer, 'text-halo-width', 1);
          }
        });

        // Define event handlers
        const handleMoveEnd = () => {
          const mapBounds = map.getBounds();
          onBoundsChanged({
            north: mapBounds.getNorth(),
            south: mapBounds.getSouth(),
            east: mapBounds.getEast(),
            west: mapBounds.getWest()
          });
        };

        const handleZoomEnd = () => {
          setZoom(map.getZoom());
        };

        // Remove any existing handlers
        map.off('moveend', handleMoveEnd);
        map.off('zoomend', handleZoomEnd);

        // Add new event listeners
        map.on('moveend', handleMoveEnd);
        map.on('zoomend', handleZoomEnd);

        setIsInitialized(true);
      });
    });
  }, [isMapLoading]);

  const handleZoomChange = (newZoom: number) => {
    if (mapboxRef.current) {
      mapboxRef.current.zoomTo(newZoom, {
        duration: 300
      });
    }
  };

  return (
    <div className="map-overlay-wrapper" style={{ height: '100%', width: '100%' }}>
      <div className="map-controls">
        <ZoomControl zoom={zoom} onZoomChange={handleZoomChange} />
        <TransparencyControl opacity={opacity} onOpacityChange={setOpacity} />
      </div>
      
      <div 
        ref={containerRef} 
        className="map-overlay-container"
        style={{ 
          height: 'calc(100% - 60px)',
          width: '100%',
        }}
      >
        {isLoading ? (
          <div className="map-loading">Loading map...</div>
        ) : (
          <div 
            className="map-overlay"
            style={{ 
              width: '100%',
              height: '100%',
              position: 'relative',
            }} 
          >
            <img 
              ref={imageRef}
              src={imageUrl}
              alt="Map overlay"
              style={{
                display: 'block',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                zIndex: 1,
              }}
            />
            
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2,
              opacity: opacity,
            }}>
              {isMapLoading && (
                <div className="map-loading">Initializing map...</div>
              )}
              <div 
                ref={mapDivRef}
                style={{ 
                  width: '100%', 
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  visibility: isMapLoading ? 'hidden' : 'visible'
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapOverlay; 