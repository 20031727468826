import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import Login from './screens/Login/Login';
import VersionList from './screens/VersionList/VersionList';
import Editor from './screens/Editor/Editor';
import CreateFestival from './screens/CreateFestival/CreateFestival';
import Admin from './screens/Admin/Admin';
import CreateUser from './screens/Admin/CreateUser';
import FestivalsList from './screens/Admin/FestivalsList';
import Cookies from 'js-cookie';
import { Session } from './model/Session';
import { User } from './model/User';
import { EditorType } from './model/Roles';
import ResetPassword from './screens/Login/ResetPassword';
import Migrations from './screens/Admin/Migrations';

const editorTabs: EditorType[] = [
  'infos',
  'map',
  'tickets',
  'news',
  'artists',
  'pois',
  'shows',
  'notifications',
  'users',
  'history',
  'carpools',
  'reporting'
];

console.log('API Base URL:', process.env.REACT_APP_API_BASE_URL);

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const savedSession = Cookies.get('session');
    if (savedSession) {
      const parsedSession: Session = JSON.parse(savedSession);
      setSession(parsedSession);
      setIsLoggedIn(true);
    }
    setIsLoading(false);
  }, []);

  const handleLogin = (token: string, festivalId: string, user: User) => {
    const newSession: Session = { token, festivalId, user };
    setSession(newSession);
    Cookies.set('session', JSON.stringify(newSession), { expires: 60 });
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setSession(null);
    Cookies.remove('session');
  };

  const handleFestivalCreated = (newFestivalId: string, adminEmail: string) => {
    window.location.href = `/?festival_id=${newFestivalId}`;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          {isLoggedIn && session ? (
            <VersionList
              sessionToken={session.token}
              festivalId={session.festivalId}
              onLogout={handleLogout}
            />
          ) : (
            <Login onLogin={handleLogin} />
          )}
        </Route>
        <Route exact path="/admin">
          <Admin />
        </Route>
        <Route path="/admin/create-festival">
          <CreateFestival
            onBack={() => window.location.href = '/admin'}
            onFestivalCreated={handleFestivalCreated}
          />
        </Route>
        <Route path="/admin/create-user">
          <CreateUser />
        </Route>
        <Route path="/admin/festivals-list">
          <FestivalsList />
        </Route>
        <Route path="/admin/migrations">
          <Migrations />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        {editorTabs.map(tab => (
          <Route key={tab} path={`/editor/:versionId/${tab}`}>
            {isLoggedIn && session ? (
              <Editor
                sessionToken={session.token}
                festivalId={session.festivalId}
                currentUser={session.user}
                defaultTab={tab}
              />
            ) : isLoading ? (
              <div>Loading...</div>
            ) : (
              <Redirect 
                to={{
                  pathname: "/",
                  state: { from: window.location.pathname }
                }}
              />
            )}
          </Route>
        ))}
        <Route 
          path="/editor/:versionId"
          render={({ match }) => (
            isLoggedIn && session ? (
              <Redirect to={`/editor/${match.params.versionId}/infos`} />
            ) : isLoading ? (
              <div>Loading...</div>
            ) : (
              <Redirect to="/" />
            )
          )}
        />
      </Switch>
    </Router>
  );
};

export default App;
