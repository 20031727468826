import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import './Login.css';
import { login, getFestivalIdFromSubdomain } from '../../services/api';
import { User } from '../../model/User';

interface LoginProps {
  onLogin: (sessionToken: string, festivalId: string, user: User) => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [festivalId, setFestivalId] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isFestivalIdLocked, setIsFestivalIdLocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const fetchFestivalId = async () => {
      // First check URL parameter
      const params = new URLSearchParams(location.search);
      const urlFestivalId = params.get('festival_id');
      
      if (urlFestivalId) {
        setFestivalId(urlFestivalId);
        setIsFestivalIdLocked(true);
        return;
      }

      // If no URL parameter, try to get from subdomain
      try {
        const hostname = window.location.hostname;
        // Extract subdomain (everything before first dot)
        const subdomain = hostname.split('.')[0];
        
        // Only try to fetch if we're not on localhost and have a subdomain
        if (hostname !== 'localhost' && hostname !== '127.0.0.1' && subdomain !== hostname) {
          const festivalIdFromSubdomain = await getFestivalIdFromSubdomain(subdomain);
          if (festivalIdFromSubdomain) {
            setFestivalId(festivalIdFromSubdomain);
            setIsFestivalIdLocked(true);
            return;
          } else {
            console.log("Couldn't find festival ID from subdomain: ", subdomain);
          }
        }
      } catch (err) {
        console.warn('Failed to fetch festival ID from subdomain: ', err);
      }

      // If no subdomain match, try localStorage
      const lastUsedFestivalId = localStorage.getItem('lastUsedFestivalId');
      if (lastUsedFestivalId) {
        setFestivalId(lastUsedFestivalId);
      }
    };

    fetchFestivalId();
  }, [location]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      const response = await login(email, password, festivalId);
      localStorage.setItem('lastUsedFestivalId', festivalId);
      onLogin(response.token, festivalId, response.user);
    } catch (err) {
      console.error('Login error:', err);
      setError(err instanceof Error ? err.message : 'An error occurred during login');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = () => {
    const params = new URLSearchParams();
    if (festivalId) {
      params.append('festival_id', festivalId);
    }
    if (email) {
      params.append('email', email);
    }
    history.push(`/reset-password?${params.toString()}`);
  };

  return (
    <div className="login-container">
      <div className="login-form-wrapper">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2>Login</h2>
          {!isFestivalIdLocked && (
            <input
              type="text"
              placeholder="Festival ID"
              value={festivalId}
              onChange={(e) => setFestivalId(e.target.value)}
              required
              disabled={isLoading}
            />
          )}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isLoading}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isLoading}
          />
          <button 
            type="submit" 
            disabled={isLoading}
            className={isLoading ? 'loading' : ''}
          >
            {isLoading ? 'Logging in...' : 'Login'}
          </button>
          <button 
            type="button" 
            onClick={handleResetPassword}
            className="reset-password-button"
            disabled={isLoading}
          >
            Reset Password
          </button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;
