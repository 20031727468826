import { FestivalData, DataDiff } from '../model/FestivalData';
import deepEqual from 'deep-equal';

interface IdentifiableItem {
  identifier: string;
  [key: string]: any;
}

export function calculateDiff(oldData: FestivalData, newData: FestivalData): DataDiff[] {
  const diffs: DataDiff[] = [];

  function compareObjects(oldObj: any, newObj: any, path: string) {
    // Handle primitive values
    if (typeof oldObj !== 'object' || typeof newObj !== 'object' || oldObj === null || newObj === null) {
      if (!deepEqual(oldObj, newObj)) {
        diffs.push({
          type: 'modified',
          path,
          oldValue: oldObj,
          newValue: newObj
        });
      }
      return;
    }

    // Handle arrays
    if (Array.isArray(oldObj)) {
      const oldItems = oldObj as IdentifiableItem[];
      const newItems = newObj as IdentifiableItem[];
      
      const oldIds = new Set(oldItems.map(item => item.identifier));
      const newIds = new Set(newItems.map(item => item.identifier));

      // Find added items
      newItems.forEach(item => {
        if (!oldIds.has(item.identifier)) {
          diffs.push({
            type: 'added',
            path: `${path}[${item.identifier}]`,
            newValue: item
          });
        }
      });

      // Find removed items
      oldItems.forEach(item => {
        if (!newIds.has(item.identifier)) {
          diffs.push({
            type: 'removed',
            path: `${path}[${item.identifier}]`,
            oldValue: item
          });
        }
      });

      // Compare modified items
      oldItems.forEach(oldItem => {
        const newItem = newItems.find(item => item.identifier === oldItem.identifier);
        if (newItem && !deepEqual(oldItem, newItem)) {
          diffs.push({
            type: 'modified',
            path: `${path}[${oldItem.identifier}]`,
            oldValue: oldItem,
            newValue: newItem
          });
        }
      });
      return;
    }

    // Handle objects
    const allKeys = new Set([...Object.keys(oldObj), ...Object.keys(newObj)]);
    
    allKeys.forEach(key => {
      const newPath = path ? `${path}.${key}` : key;
      
      if (!(key in oldObj)) {
        diffs.push({
          type: 'added',
          path: newPath,
          newValue: newObj[key]
        });
      } else if (!(key in newObj)) {
        diffs.push({
          type: 'removed',
          path: newPath,
          oldValue: oldObj[key]
        });
      } else if (!deepEqual(oldObj[key], newObj[key])) {
        compareObjects(oldObj[key], newObj[key], newPath);
      }
    });
  }

  compareObjects(oldData, newData, '');
  return diffs;
} 