import React from 'react';
import { DataDiff } from '../model/FestivalData';

interface ConflictModalProps {
  diffs: DataDiff[];
  onOverwrite: () => void;
  onReload: () => void;
  onClose: () => void;
}

const ConflictModal: React.FC<ConflictModalProps> = ({
  diffs,
  onOverwrite,
  onReload,
  onClose,
}) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content conflict-modal">
        <h2>Update Conflict Detected</h2>
        <p>A newer version exists on the server.</p>
        
        <div className="diff-container">
          <h3>Changes made by others:</h3>
          {diffs.map((diff, index) => (
            <div key={index} className={`diff-item diff-${diff.type}`}>
              <span className="diff-path">{diff.path}</span>
              {diff.type === 'modified' && (
                <>
                  <div className="diff-old">- {JSON.stringify(diff.oldValue)}</div>
                  <div className="diff-new">+ {JSON.stringify(diff.newValue)}</div>
                </>
              )}
              {diff.type === 'added' && (
                <div className="diff-new">+ {JSON.stringify(diff.newValue)}</div>
              )}
              {diff.type === 'removed' && (
                <div className="diff-old">- {JSON.stringify(diff.oldValue)}</div>
              )}
            </div>
          ))}
        </div>

        <div className="conflict-actions">
          <button onClick={onOverwrite} className="danger">
            Overwrite with these changes
          </button>
          <button onClick={onReload} className="secondary">
            Reload from server
          </button>
          <button onClick={onClose} className="tertiary">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConflictModal; 