import { FestivalData, DataDiff } from '../model/FestivalData';
import { fetchCurrentVersion, updateFestivalData } from './api';
import { calculateDiff } from '../utils/diffCalculator';
import { ImageService } from './imageService';
import { formatDateWithoutMilliseconds } from '../utils/dateUtils';

export class FestivalDataService {
  private imageService: ImageService;

  constructor(
    private sessionToken: string,
    private festivalId: string
  ) {
    this.imageService = new ImageService(sessionToken, festivalId);
  }

  async loadVersion(versionId: string): Promise<FestivalData> {
    return await fetchCurrentVersion(this.sessionToken, this.festivalId, versionId);
  }

  async checkForConflicts(currentData: FestivalData): Promise<DataDiff[] | null> {
    if (currentData.updated_at === null) return null;

    const serverVersion = await this.loadVersion(currentData.version.toString());
    
    if (serverVersion.updated_at !== currentData.updated_at) {
      const diffs = calculateDiff(currentData, serverVersion);
      if (diffs.length > 0) {
        return diffs;
      }
    }
    
    return null;
  }

  async saveVersion(data: FestivalData): Promise<{ updated_at: string }> {
    console.log("Uploading images...");
    const dataWithUploadedImages = await this.imageService.uploadAllImages(data);

    // Format dates
    const dataWithFormattedDates = {
      ...dataWithUploadedImages,
      shows: dataWithUploadedImages.shows.map(show => ({
        ...show,
        start_date: formatDateWithoutMilliseconds(show.start_date),
        end_date: formatDateWithoutMilliseconds(show.end_date)
      }))
    };

    console.log("Images uploaded, saving data...");
    
    return await updateFestivalData(
      this.sessionToken,
      this.festivalId,
      dataWithFormattedDates.version.toString(),
      dataWithFormattedDates
    );
  }

  getImageUrl(identifier: string): string {
    return this.imageService.getImageUrl(identifier);
  }
} 