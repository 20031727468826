import React, { useState, useCallback, useEffect } from 'react';
import { FestivalData, ImageData, NewsItem } from '../../../model/FestivalData';
import ImageEditor from './ImageEditor';
import { fetchNews, updateNews, deleteNewsItem } from '../../../services/api';
import { getImageUrl, ImageService } from '../../../services/imageService';
import { formatDateWithoutMilliseconds } from '../../../utils/dateUtils';
import { v4 as uuidv4 } from 'uuid';
import { FaImage, FaNewspaper } from 'react-icons/fa';
import './NewsEditor.css';

interface NewsEditorProps {
  sessionToken: string;
  festivalId: string;
  getApiImageUrl: (identifier: string) => string;
}

const NewsEditor: React.FC<NewsEditorProps> = ({ 
  sessionToken, 
  festivalId, 
  getApiImageUrl,
}) => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [editingNews, setEditingNews] = useState<NewsItem | null>(null);
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [savingIdentifier, setSavingIdentifier] = useState<string | null>(null);
  const [deletingIdentifier, setDeletingIdentifier] = useState<string | null>(null);
  const [unsavedNewsIds, setUnsavedNewsIds] = useState<string[]>([]);

  const imageService = new ImageService(sessionToken, festivalId);

  useEffect(() => {
    const loadNews = async () => {
      try {
        const data = await fetchNews(sessionToken, festivalId);
        setNewsItems(data);
      } catch (error) {
        console.error('Failed to load news:', error);
      } finally {
        setLoading(false);
      }
    };
    loadNews();
  }, [sessionToken, festivalId]);

  const handleAddNews = useCallback(() => {
    const newNewsItem: NewsItem = {
      identifier: uuidv4(),
      created_at: formatDateWithoutMilliseconds(new Date().toISOString()),
      title: '[Title]',
      image: null,
      summary: '',
      html_body: '',
    };
    
    setUnsavedNewsIds(prev => [...prev, newNewsItem.identifier]);
    setNewsItems(prevItems => [...prevItems, newNewsItem]);
    setEditingNews(newNewsItem);
  }, []);

  const handleSaveNews = useCallback(async (updatedNewsItem: NewsItem) => {
    try {
      setSavingIdentifier(updatedNewsItem.identifier);
      setSaveError(null);
      let newsToSave = { 
        ...updatedNewsItem,
        created_at: formatDateWithoutMilliseconds(updatedNewsItem.created_at)
      };
      
      if (newsToSave.image) {
        const uploadedImage = await imageService.uploadImageData(newsToSave.image);
        newsToSave = {
          ...newsToSave,
          image: uploadedImage
        };
      }

      await updateNews(sessionToken, festivalId, newsToSave);
      setNewsItems(prevItems => 
        prevItems.map(item => 
          item.identifier === newsToSave.identifier ? newsToSave : item
        )
      );
      setUnsavedNewsIds(prev => prev.filter(id => id !== newsToSave.identifier));
      setEditingNews(null);
    } catch (error) {
      console.error('Failed to save news item:', error);
      setSaveError(error instanceof Error ? error.message : 'Failed to save news item');
    } finally {
      setSavingIdentifier(null);
    }
  }, [sessionToken, festivalId, imageService]);

  const handleRemoveNews = useCallback(async (identifier: string) => {
    try {
      setDeletingIdentifier(identifier);
      await deleteNewsItem(sessionToken, festivalId, identifier);
      const updatedNews = newsItems.filter(item => item.identifier !== identifier);
      setNewsItems(updatedNews);
      setEditingNews(null);
    } catch (error) {
      console.error('Failed to delete news item:', error);
    } finally {
      setDeletingIdentifier(null);
    }
  }, [newsItems, sessionToken, festivalId]);

  const handleEditNews = useCallback((newsItem: NewsItem) => {
    setEditingNews(editingNews?.identifier === newsItem.identifier ? null : newsItem);
  }, [editingNews]);

  const handleNewsImageChange = useCallback((newImage: ImageData | undefined) => {
    if (editingNews) {
      const updatedNewsItem = { ...editingNews, image: newImage || null };
      setEditingNews(updatedNewsItem);
    }
  }, [editingNews]);

  const handleCancelEdit = useCallback((newsItem: NewsItem) => {
    setSaveError(null);
    if (unsavedNewsIds.includes(newsItem.identifier)) {
      setNewsItems(prevItems => 
        prevItems.filter(item => item.identifier !== newsItem.identifier)
      );
      setUnsavedNewsIds(prev => prev.filter(id => id !== newsItem.identifier));
    }
    setEditingNews(null);
  }, [unsavedNewsIds]);

  if (loading) {
    return <div>Loading news...</div>;
  }

  return (
    <section className="news-editor">
      <h2>News</h2>
      <button onClick={handleAddNews} className="add-news-button">Add News</button>
      <ul className="news-list">
        {[...newsItems]
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
          .map((item) => (
          <li key={item.identifier} className="news-item">
            <div className="news-item-header" onClick={() => handleEditNews(item)}>
              <div className="news-item-preview">
                {item.image ? (
                  <img 
                    src={getImageUrl(item.image, getApiImageUrl)} 
                    alt={item.title}
                    className="news-thumbnail"
                  />
                ) : (
                  <div className="news-thumbnail-placeholder">
                    <FaNewspaper />
                  </div>
                )}
                <h4>{item.title}</h4>
              </div>
              <span>{new Date(item.created_at).toLocaleDateString()}</span>
            </div>
            {editingNews?.identifier === item.identifier && (
              <div className="news-item-edit">
                {saveError && (
                  <div className="error-message" style={{ color: 'red', marginBottom: '1rem' }}>
                    {saveError}
                  </div>
                )}
                <input
                  type="text"
                  value={editingNews.title}
                  onChange={(e) => setEditingNews({ ...editingNews, title: e.target.value })}
                  placeholder="Title"
                />
                <textarea
                  value={editingNews.summary}
                  onChange={(e) => setEditingNews({ ...editingNews, summary: e.target.value })}
                  placeholder="Summary"
                />
                <textarea
                  value={editingNews.html_body}
                  onChange={(e) => setEditingNews({ ...editingNews, html_body: e.target.value })}
                  placeholder="HTML Body"
                />
                <ImageEditor
                  currentImage={editingNews.image || undefined}
                  onChange={handleNewsImageChange}
                  getApiImageUrl={getApiImageUrl}
                />
                <div className="news-item-actions">
                  <button 
                    onClick={() => handleSaveNews(editingNews)}
                    disabled={savingIdentifier === item.identifier}
                  >
                    {savingIdentifier === item.identifier ? 'Saving...' : 'Save'}
                  </button>
                  <button onClick={() => handleCancelEdit(item)}>Cancel</button>
                  {!unsavedNewsIds.includes(item.identifier) && (
                    <button 
                      onClick={() => handleRemoveNews(item.identifier)}
                      disabled={deletingIdentifier === item.identifier}
                    >
                      {deletingIdentifier === item.identifier ? 'Removing...' : 'Remove'}
                    </button>
                  )}
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default NewsEditor;