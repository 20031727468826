import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Modal from '../../components/Modal';
import { generateRecoverPasswordCode, updatePassword } from '../../services/api';
import './ResetPassword.css';

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [festivalId, setFestivalId] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationCode, setValidationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlFestivalId = params.get('festival_id');
    const urlEmail = params.get('email');
    
    if (urlFestivalId) {
      setFestivalId(urlFestivalId);
    }
    if (urlEmail) {
      setEmail(urlEmail);
    }
  }, [location]);

  const handleGenerateCode = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      await generateRecoverPasswordCode(email, festivalId);
      setIsModalOpen(true);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to generate reset code');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== repeatPassword) {
      return;
    }

    setError(null);
    setIsLoading(true);

    try {
      await updatePassword(email, validationCode, newPassword, festivalId);
      history.push(festivalId ? `/?festival_id=${festivalId}` : '/');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update password');
      setIsModalOpen(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-form-wrapper">
        <form className="reset-password-form" onSubmit={handleGenerateCode}>
          <h2>Reset Password</h2>
          {!festivalId && (
            <input
              type="text"
              placeholder="Festival ID"
              value={festivalId}
              onChange={(e) => setFestivalId(e.target.value)}
              required
              disabled={isLoading}
            />
          )}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isLoading}
          />
          <button 
            type="submit" 
            disabled={isLoading}
            className={isLoading ? 'loading' : ''}
          >
            {isLoading ? 'Sending...' : 'Reset Password'}
          </button>
          {error && <p className="error-message">{error}</p>}
        </form>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <form className="password-update-form" onSubmit={handlePasswordUpdate}>
            <h3>Enter New Password</h3>
            <input
              type="text"
              placeholder="Validation Code"
              value={validationCode}
              onChange={(e) => setValidationCode(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Repeat Password"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              required
            />
            <button 
              type="submit"
              disabled={newPassword !== repeatPassword || isLoading}
            >
              Update Password
            </button>
            {newPassword !== repeatPassword && (
              <p className="error-message">Passwords do not match</p>
            )}
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default ResetPassword; 