import React from 'react';
import { Link } from 'react-router-dom';
import './Admin.css';

const Admin: React.FC = () => {
  return (
    <div className="admin-container">
      <div className="admin-content">
        <h1>Admin Panel</h1>
        <div className="admin-menu">
          <Link to="/admin/create-festival" className="admin-button">
            Create Festival
          </Link>
          <Link to="/admin/create-user" className="admin-button">
            Create User
          </Link>
          <Link to="/admin/festivals-list" className="admin-button">
            Festivals List
          </Link>
          <Link to="/admin/migrations" className="admin-button">
            Perform Migrations
          </Link>
          <Link to="/" className="admin-button back">
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Admin; 