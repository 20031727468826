import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchFestivals } from '../../services/api';
import './FestivalsList.css';

interface Festival {
  festival_id: string;
  names: string[];
}

const FestivalsList: React.FC = () => {
  const [masterKey, setMasterKey] = useState('');
  const [festivals, setFestivals] = useState<Festival[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    setHasSubmitted(true);

    try {
      const fetchedFestivals = await fetchFestivals(masterKey);
      setFestivals(fetchedFestivals);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFestivalClick = (festivalId: string) => {
    history.push(`/?festival_id=${festivalId}`);
  };

  return (
    <div className="festivals-list-container">
      <div className="festivals-list-content">
        <h1>Festivals List</h1>
        {!hasSubmitted ? (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="masterKey">Master Key</label>
              <input
                type="password"
                id="masterKey"
                value={masterKey}
                onChange={(e) => setMasterKey(e.target.value)}
                required
              />
            </div>
            <button type="submit">View Festivals</button>
          </form>
        ) : (
          <>
            {isLoading ? (
              <div className="loader">Loading...</div>
            ) : (
              <div className="festivals-grid">
                {festivals.map((festival) => (
                  <div
                    key={festival.festival_id}
                    className="festival-item"
                    onClick={() => handleFestivalClick(festival.festival_id)}
                  >
                    <h3>Festival ID: {festival.festival_id}</h3>
                    <p>Names: {festival.names.join(', ')}</p>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        {error && <p className="error-message">{error}</p>}
        <button 
          className="back-button"
          onClick={() => history.push('/admin')}
        >
          Back to Admin
        </button>
      </div>
    </div>
  );
};

export default FestivalsList; 