import { FestivalData, NewsItem } from '../model/FestivalData';
import { User } from '../model/User';
import { Carpool } from '../model/Carpool';
import { CarpoolReport } from '../model/Report';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


interface LoginResponse {
  token: string;
  user: User;
}

export const login = async (email: string, password: string, festivalId: string): Promise<LoginResponse> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Festival-Id': festivalId,
    },
    body: JSON.stringify({ email, password }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Login failed');
  }

  return {
    token: data.data.session.token,
    user: data.data.user
  };
};

export const fetchVersions = async (sessionToken: string, festivalId: string): Promise<FestivalData[]> => {
  console.log("Fetching versions from " + festivalId + " using token " + sessionToken);
  const response = await fetch(`${API_BASE_URL}/api/v1/festival/versions`, {
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Failed to fetch versions.');
  }

  return data.data.versions;
};

export const updateFestivalData = async (
  sessionToken: string, 
  festivalId: string, 
  versionId: string, 
  data: FestivalData
): Promise<{ updated_at: string }> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/festival/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    },
    body: JSON.stringify({ festival_data: data }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to update festival data.');
  }

  const responseData = await response.json();
  return { updated_at: responseData.data.updated_at };
};

export const uploadImage = async (file: File, sessionToken: string, festivalId: string): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(`${API_BASE_URL}/api/v1/resources`, {
    method: 'POST',
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    },
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to upload image.');
  }

  const data = await response.json();
  return data.data.file_identifier;
};

export const imageUrl = (identifier: string, festivalId: string) => {
  return `${API_BASE_URL}/api/v1/resources/${identifier}?festival_id=${festivalId}`;
};

export const fetchCurrentVersion = async (
  sessionToken: string,
  festivalId: string,
  versionId: string
): Promise<FestivalData> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/festival/versions`, {
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to fetch current version.');
  }

  const data = await response.json();
  const currentVersion = data.data.versions.find(
    (version: FestivalData) => version.version.toString() === versionId
  );

  if (!currentVersion) {
    throw new Error('Version not found');
  }

  return currentVersion;
};

export const createUserUserMasterKey = async (
  masterKey: string, 
  festivalId: string, 
  email: string, 
  roles: string[]
) => {
  const response = await fetch(`${API_BASE_URL}/api/v1/user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Master-Key': masterKey,
      'X-Festival-Id': festivalId,
    },
    body: JSON.stringify({
      email,
      roles,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message ? `Failed to create user: ${data.message}` : 'Failed to create user');
  }

  return data;
};

export const createUser = async (
  sessionToken: string,
  festivalId: string, 
  email: string, 
  roles: string[]
) => {
  const response = await fetch(`${API_BASE_URL}/api/v1/user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Session-Token': sessionToken,
      'X-Festival-Id': festivalId,
    },
    body: JSON.stringify({
      email,
      roles,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message ? `Failed to create user: ${data.message}` : 'Failed to create user');
  }

  return data;
};

export const createFestival = async (
  masterKey: string,
  festivalName: string,
  adminEmail: string,
  firebaseSecretKey: object,
  subdomain: string
): Promise<{ festival_id: string }> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/festival/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Master-Key': masterKey,
    },
    body: JSON.stringify({
      festival_name: festivalName,
      admin_email: adminEmail,
      firebase_secret_key: firebaseSecretKey,
      subdomain: subdomain,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Failed to create festival');
  }

  return { festival_id: data.data.festival_id };
};

export interface RevisionExcerpt {
  uuid: string;
  user_email: string;
  date: string;
  version: number;
  diff: string;
}

export const fetchRevisionsExcerpt = async (
  sessionToken: string,
  festivalId: string,
  versionId: string
): Promise<RevisionExcerpt[]> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/revisions/${versionId}`, {
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to fetch revisions');
  }

  const data = await response.json();
  return data.data.revisions;
};

export const revertToRevision = async (
  sessionToken: string,
  festivalId: string,
  versionId: string,
  revisionUuid: string
): Promise<FestivalData> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/revisions/revert/${versionId}/${revisionUuid}`, {
    method: 'POST',
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to revert to revision');
  }

  const data = await response.json();
  return data.data.festival_data;
};

export const sendNotification = async (
  sessionToken: string,
  festivalId: string,
  notification: {
    topic: string;
    title: string;
    body: string;
  }
): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/notification`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    },
    body: JSON.stringify(notification),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to send notification.');
  }
};

interface Festival {
  festival_id: string;
  names: string[];
}

export const fetchFestivals = async (masterKey: string): Promise<Festival[]> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/festivals`, {
    headers: {
      'X-Master-Key': masterKey,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to fetch festivals');
  }

  const data = await response.json();
  return data.data.festivals;
};

// Add new interface and API call for fetching full revision details
export interface Revision extends RevisionExcerpt {
  diff: string;  // Full diff content
}

export const fetchRevision = async (
  sessionToken: string,
  festivalId: string,
  revisionId: string
): Promise<Revision> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/revision/${revisionId}`, {
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to fetch revision details');
  }

  const data = await response.json();
  return data.data.revision;
};

export const fetchUsers = async (
  sessionToken: string,
  festivalId: string
): Promise<User[]> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/users`, {
    headers: {
      'X-Session-Token': sessionToken,
      'X-Festival-Id': festivalId,
    }
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message ? `Failed to fetch users: ${data.message}` : 'Failed to fetch users');
  }

  return data.data.users;
};

export const updateUserRoles = async (
  sessionToken: string,
  festivalId: string,
  userId: string,
  roles: string[]
): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/user/${userId}/roles`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Session-Token': sessionToken,
      'X-Festival-Id': festivalId,
    },
    body: JSON.stringify({ roles }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message ? `Failed to update roles: ${data.message}` : 'Failed to update roles');
  }
};

// Add this new function to fetch current user data
export const fetchCurrentUser = async (
  sessionToken: string,
  festivalId: string
): Promise<User> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/current_user`, {
    headers: {
      'X-Session-Token': sessionToken,
      'X-Festival-Id': festivalId,
    }
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message ? `Failed to fetch user data: ${data.message}` : 'Failed to fetch user data');
  }

  return data.data.user;
};

export const deleteUser = async (
  sessionToken: string,
  festivalId: string,
  userId: string
): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/user/${userId}`, {
    method: 'DELETE',
    headers: {
      'X-Session-Token': sessionToken,
      'X-Festival-Id': festivalId,
    }
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message ? `Failed to delete user: ${data.message}` : 'Failed to delete user');
  }
};

export const resetUserPassword = async (
  sessionToken: string,
  festivalId: string,
  userId: string
): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/user/${userId}/reset_password`, {
    method: 'POST',
    headers: {
      'X-Session-Token': sessionToken,
      'X-Festival-Id': festivalId,
    }
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Failed to reset password');
  }
};

export const generateRecoverPasswordCode = async (email: string, festivalId: string): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/user/generate_recover_password_code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Festival-Id': festivalId,
    },
    body: JSON.stringify({ email }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Failed to generate recovery code');
  }
};

export const updatePassword = async (
  email: string,
  otp: string,
  password: string,
  festivalId: string
): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/user/update_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Festival-Id': festivalId,
    },
    body: JSON.stringify({ email, otp, password }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Failed to update password');
  }
};

export const performMigrations = async (masterKey: string): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/migrate`, {
    method: 'GET',
    headers: {
      'X-Master-Key': masterKey,
    }
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Failed to perform migrations');
  }
};

export const getFestivalIdFromSubdomain = async (subdomain: string): Promise<string> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/festival/subdomain/${subdomain}`);
  if (!response.ok) {
    throw new Error('Failed to fetch festival ID from subdomain');
  }
  const data = await response.json();
  return data.data.festival_id;
};

export interface CarpoolsResponse {
  carpools: Carpool[];
}

export const fetchCarpools = async (
  sessionToken: string,
  festivalId: string
): Promise<Carpool[]> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/carpools`, {
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to fetch carpools');
  }

  const data = await response.json();
  return data.data.carpools;
};

export const deleteCarpool = async (
  sessionToken: string,
  festivalId: string,
  carpoolId: string
): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/carpool/${carpoolId}`, {
    method: 'DELETE',
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to delete carpool');
  }
};

export const fetchReports = async (
  sessionToken: string,
  festivalId: string
): Promise<CarpoolReport[]> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/reports`, {
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to fetch reports');
  }

  const data = await response.json();
  return data.data.carpool_reports;
};

export const ignoreReport = async (
  sessionToken: string,
  festivalId: string,
  reportId: string
): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/report/${reportId}/ignore`, {
    method: 'POST',
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to ignore report');
  }
};

export const applyReport = async (
  sessionToken: string,
  festivalId: string,
  reportId: string
): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/report/${reportId}/apply`, {
    method: 'POST',
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to apply report');
  }
};

// Add these new functions
export const fetchNews = async (
  sessionToken: string,
  festivalId: string
): Promise<NewsItem[]> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/news`, {
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to fetch news');
  }

  const data = await response.json();
  return data.data.news;
};

export const updateNews = async (
  sessionToken: string,
  festivalId: string,
  newsItem: NewsItem
): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/news`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    },
    body: JSON.stringify({ news: newsItem }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to update news');
  }
};

export const deleteNewsItem = async (
  sessionToken: string,
  festivalId: string,
  newsId: string
): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/api/v1/news/${newsId}`, {
    method: 'DELETE',
    headers: {
      'X-Festival-Id': festivalId,
      'X-Session-Token': sessionToken,
    }
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to delete news item');
  }
};
