import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './CreateUser.css';
import { createUserUserMasterKey } from '../../services/api';
import { Role } from '../../model/Roles';

// Add interface for role descriptions
interface RoleConfig {
  value: string;
  description: string;
}

// Updated role configurations with descriptions
const ROLES: RoleConfig[] = [
  { value: Role.ADMIN, description: "Administrator - Full access to all features" },
  { value: Role.NEWS, description: "News Editor - Can create and edit news" },
  { value: Role.NOTIFICATION_PUSH, description: "Notifications - Can send push notifications" },
];

const CreateUser: React.FC = () => {
  const [masterKey, setMasterKey] = useState('');
  const [festivalId, setFestivalId] = useState('');
  const [email, setEmail] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();

  const handleRoleToggle = (role: Role) => {
    setSelectedRoles(prevRoles => {
      if (prevRoles.includes(role)) {
        return prevRoles.filter(r => r !== role);
      } else {
        return [...prevRoles, role];
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      await createUserUserMasterKey(masterKey, festivalId, email, selectedRoles);
      alert('User created successfully');
      history.push('/admin');
    } catch (err) {
      if (err instanceof Error) {
        // Extract the server message if it exists in the error message
        const serverMessage = err.message.includes('Failed to create user:') 
          ? err.message 
          : `Failed to create user: ${err.message}`;
        setError(serverMessage);
      } else {
        setError('An error occurred');
      }
    }
  };

  return (
    <div className="create-user-container">
      <div className="create-user-content">
        <h1>Create User</h1>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="masterKey">Master Key</label>
            <input
              type="password"
              id="masterKey"
              value={masterKey}
              onChange={(e) => setMasterKey(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="festivalId">Festival ID</label>
            <input
              type="text"
              id="festivalId"
              value={festivalId}
              onChange={(e) => setFestivalId(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>User Roles</label>
            <div className="roles-selection">
              {ROLES.map(({ value, description }) => (
                <label key={value} className="role-checkbox">
                  <input
                    type="checkbox"
                    checked={selectedRoles.includes(value)}
                    onChange={() => handleRoleToggle(value as Role)}
                  />
                  <span className="role-description"> {description}</span>
                </label>
              ))}
            </div>
          </div>
          <div className="form-actions">
            <button type="submit">Create User</button>
            <button type="button" onClick={() => history.push('/admin')} className="back-button">
              Back
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateUser; 