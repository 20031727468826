import React from 'react';
import { FestivalData, ImageData } from '../../../model/FestivalData';
import ImageEditor from './ImageEditor';

interface InfosEditorProps {
  infos: FestivalData['infos'];
  onChange: (newInfos: FestivalData['infos']) => void;
  getApiImageUrl: (identifier: string) => string;
}

const InfosEditor: React.FC<InfosEditorProps> = ({ infos, onChange, getApiImageUrl }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    onChange({
      ...infos,
      [name]: value
    });
  };

  const handleLogoChange = (newImage: ImageData | undefined) => {
    onChange({
      ...infos,
      festival_logo: newImage || null
    });
  };

  const handleTicketImageChange = (newImage: ImageData | undefined) => {
    // Ensure we maintain the required fields
    onChange({
      ...infos,
      tickets: {
        description: infos.tickets?.description || '',  // Provide default empty string
        link: infos.tickets?.link || '',               // Provide default empty string
        image: newImage || null
      }
    });
  };

  const handleNewsHeaderImageChange = (newImage: ImageData | undefined) => {
    onChange({
      ...infos,
      news_header_image: newImage || null
    });
  };

  return (
    <section className="infos-editor">
      <h2>Infos and Layout</h2>
      <p className="field-description">Basic informations and configuration of UI.</p>
      
      <div className="form-group">
        <label htmlFor="festival_name">Festival Name:</label>
        <input
          id="festival_name"
          type="text"
          name="festival_name"
          value={infos.festival_name}
          onChange={handleChange}
        />
        <span className="field-description">The official name of the festival.</span>
      </div>

      <div className="form-group">
        <div className="image-editors-grid">
          <ImageEditor
            currentImage={infos.festival_logo || undefined}
            onChange={handleLogoChange}
            getApiImageUrl={getApiImageUrl}
            fillMode="contain"
            title="Festival Logo"
            caption="Will be displayed in the app header"
          />
          <ImageEditor
            currentImage={infos.news_header_image || undefined}
            onChange={handleNewsHeaderImageChange}
            getApiImageUrl={getApiImageUrl}
            fillMode="cover"
            title="News Header"
            caption="Displayed at the top of news screen"
          />
          <ImageEditor
            currentImage={infos.tickets?.image || undefined}
            onChange={handleTicketImageChange}
            getApiImageUrl={getApiImageUrl}
            fillMode="cover"
            title="Tickets Header"
            caption="Displayed at the top of tickets screen"
          />
        </div>
      </div>
    </section>
  );
};

export default InfosEditor;
