import React, { useState } from 'react';
import { FestivalData, ImageData, Map } from '../../../model/FestivalData';
import ImageEditor from './ImageEditor';
import MapOverlay from './MapOverlay';
import { getImageUrl } from '../../../services/imageService';
import Modal from '../../../components/Modal';
import { v4 as uuidv4 } from 'uuid';
import './MapsEditor.css';

interface MapsEditorProps {
  maps: Map[];
  onChange: (newMaps: Map[]) => void;
  getApiImageUrl: (identifier: string) => string;
}

const DEFAULT_COORDINATES = { // Defaulting to France being fully visible
  top_left: { latitude: 51.107866, longitude: -7.817688 },
  bottom_right: { latitude: 41.642034, longitude: 11.797337 }
};

const MapsEditor: React.FC<MapsEditorProps> = ({ maps, onChange, getApiImageUrl }) => {
  const [editingMap, setEditingMap] = useState<Map | null>(null);
  const [tempMap, setTempMap] = useState<Map | null>(null);
  const [isEditingCoordinates, setIsEditingCoordinates] = useState(false);

  const handleAddMap = () => {
    const newMap: Map = {
      identifier: uuidv4(),
      name: 'New Map',
      image: null,
      bounds: {
        top_left: { latitude: 0, longitude: 0 },
        bottom_right: { latitude: 0, longitude: 0 }
      }
    };
    setTempMap(newMap);
    setEditingMap(newMap);
  };

  const handleEditMap = (map: Map) => {
    setTempMap({ ...map });
    setEditingMap(map);
  };

  const handleRemoveMap = (identifier: string) => {
    if (window.confirm('Are you sure you want to delete this map?')) {
      onChange(maps.filter(map => map.identifier !== identifier));
      setEditingMap(null);
      setTempMap(null);
    }
  };

  const handleSaveMap = () => {
    if (!tempMap) return;
    
    // Validate map has image and bounds set
    if (!tempMap.image) {
      alert('Please set a map image');
      return;
    }

    const hasValidBounds = 
      tempMap.bounds.top_left.latitude !== 0 ||
      tempMap.bounds.top_left.longitude !== 0 ||
      tempMap.bounds.bottom_right.latitude !== 0 ||
      tempMap.bounds.bottom_right.longitude !== 0;

    if (!hasValidBounds) {
      alert('Please set map coordinates');
      return;
    }

    // Check if we're editing an existing map or adding a new one
    const isNewMap = !maps.some(map => map.identifier === tempMap.identifier);
    
    let updatedMaps: Map[];
    if (isNewMap) {
      updatedMaps = [...maps, tempMap];
    } else {
      updatedMaps = maps.map(map => 
        map.identifier === tempMap.identifier ? tempMap : map
      );
    }
    
    onChange(updatedMaps);
    setEditingMap(null);
    setTempMap(null);
    setIsEditingCoordinates(false);
  };

  const handleImageChange = (imageData: ImageData | undefined) => {
    if (!tempMap) return;
    setTempMap({
      ...tempMap,
      image: imageData || null
    });
  };

  const handleMapBoundsChanged = (bounds: {
    north: number;
    south: number;
    east: number;
    west: number;
  }) => {
    if (!tempMap) return;
    
    const newBounds = {
      top_left: {
        latitude: bounds.north,
        longitude: bounds.west
      },
      bottom_right: {
        latitude: bounds.south,
        longitude: bounds.east
      }
    };

    setTempMap({
      ...tempMap,
      bounds: newBounds
    });
  };

  const handleEditCoordinates = () => {
    if (!tempMap) return;

    // Check if coordinates are at default values (0,0)
    const hasDefaultCoordinates = 
      tempMap.bounds.top_left.latitude == 0 &&
      tempMap.bounds.top_left.longitude == 0 &&
      tempMap.bounds.bottom_right.latitude == 0 &&
      tempMap.bounds.bottom_right.longitude == 0;

    if (hasDefaultCoordinates) {
      setTempMap({
        ...tempMap,
        bounds: DEFAULT_COORDINATES
      });
    }

    setIsEditingCoordinates(true);
  };

  const renderModalContent = () => {
    if (!tempMap) return null;

    if (isEditingCoordinates && tempMap.image) {
      return (
        <div className="coordinates-edit-view">
          <h3>Set Map Coordinates</h3>
          <div className="coordinates-map-container">
            <MapOverlay
              imageUrl={getImageUrl(tempMap.image, getApiImageUrl)!}
              onBoundsChanged={handleMapBoundsChanged}
              initialBounds={tempMap.bounds}
            />
          </div>
          <div className="modal-actions">
            <button 
              onClick={handleSaveMap}
              className="save-button"
              disabled={!tempMap.image || !tempMap.name}
            >
              Save
            </button>
            <button 
              onClick={() => setIsEditingCoordinates(false)}
              className="cancel-button"
            >
              Back
            </button>
            {editingMap?.identifier && (
              <button 
                onClick={() => handleRemoveMap(editingMap.identifier)}
                className="delete-button"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="map-edit-content">
        <h3>{editingMap?.identifier ? 'Edit Map' : 'Add Map'}</h3>
        
        <div className="form-field">
          <label>Name</label>
          <input
            type="text"
            value={tempMap.name}
            onChange={(e) => setTempMap({
              ...tempMap,
              name: e.target.value
            })}
            placeholder="Map name"
          />
        </div>

        <div className="form-field">
          <label>Map Image</label>
          <ImageEditor
            fillMode='contain'
            currentImage={tempMap.image || undefined}
            onChange={handleImageChange}
            getApiImageUrl={getApiImageUrl}
          />
        </div>

        {tempMap.image && (
          <div className="map-coordinates-section">
            <div className="coordinates-info">
              <span>Current coordinates:</span>
              <div className="coordinates-values">
                <span>
                  Top-Left: ({tempMap.bounds.top_left.latitude}, 
                  {tempMap.bounds.top_left.longitude})
                  <br />
                  Bottom-Right: ({tempMap.bounds.bottom_right.latitude}, 
                  {tempMap.bounds.bottom_right.longitude})
                </span>
              </div>
            </div>
            <button 
              className="coordinates-button"
              onClick={handleEditCoordinates}
            >
              Edit coordinates
            </button>
          </div>
        )}

        <div className="modal-actions">
          <button 
            onClick={handleSaveMap}
            className="save-button"
            disabled={!tempMap.image || !tempMap.name}
          >
            Save
          </button>
          <button 
            onClick={() => {
              setEditingMap(null);
              setTempMap(null);
              setIsEditingCoordinates(false);
            }}
            className="cancel-button"
          >
            Cancel
          </button>
          {editingMap?.identifier && (
            <button 
              onClick={() => handleRemoveMap(editingMap.identifier)}
              className="delete-button"
            >
              Delete
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="maps-editor">
      <h2>Maps</h2>
      <p className="field-description">Map images and geographical bounds of the festival areas.</p>
      <button onClick={handleAddMap} className="add-map-button">Add Map</button>
      
      <div className="maps-grid">
        {maps.map(map => (
          <div key={map.identifier} className="map-card" onClick={() => handleEditMap(map)}>
            {map.image && (
              <div className="map-image">
                <img src={getImageUrl(map.image, getApiImageUrl)} alt={map.name} />
              </div>
            )}
            <div className="map-info">
              <h3>{map.name || 'Untitled Map'}</h3>
              <span className="coordinates-preview">
                TL: ({map.bounds.top_left.latitude}, BR: {map.bounds.top_left.longitude})
              </span>
            </div>
          </div>
        ))}
        <div className="map-card add-card" onClick={handleAddMap}>
          <span>+</span>
          <h3>Add Map</h3>
        </div>
      </div>

      <Modal
        isOpen={editingMap !== null}
        onClose={() => {
          setEditingMap(null);
          setTempMap(null);
          setIsEditingCoordinates(false);
        }}
        className={`map-edit-modal ${isEditingCoordinates ? 'coordinates-mode' : ''}`}
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default MapsEditor; 