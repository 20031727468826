export enum Role {
  ADMIN = "admin",
  NEWS = "news",
  NEWS_WRITE = "news.write",
  NOTIFICATION = "notification",
  NOTIFICATION_PUSH = "notification.push",
  CARPOOLS = "carpools",
  CARPOOLS_VIEWER = "carpools.viewer",
  MODERATOR = "moderator"
}

export const SETTABLE_ROLES = [
  { value: Role.ADMIN, label: "Administrator" },
  { value: Role.NEWS_WRITE, label: "News Editor" },
  { value: Role.NOTIFICATION_PUSH, label: "Notifications" },
  { value: Role.CARPOOLS, label: "Carpools admin" },
  { value: Role.CARPOOLS_VIEWER, label: "Carpools viewer" },
  { value: Role.MODERATOR, label: "Moderator" },
];

export type EditorType = 'infos' | 'news' | 'pois' | 'artists' | 'shows' | 'map' | 'tickets' | 'history' | 'notifications' | 'users' | 'carpools' | 'reporting';

export const getAccessibleEditors = (roles: string[]): EditorType[] => {
  const allowedEditors = new Set<EditorType>();

  // Admin has access to everything
  if (roles.includes(Role.ADMIN)) {
    return ['infos', 'news', 'pois', 'artists', 'shows', 'map', 'tickets', 'history', 'notifications', 'users', 'carpools', 'reporting'];
  }

  // Add moderator access
  if (roles.includes(Role.MODERATOR)) {
    allowedEditors.add('reporting');
  }

  // News related roles
  if (roles.some(role => role.startsWith('news'))) {
    allowedEditors.add('news');
  }

  // Notification related roles
  if (roles.some(role => role.startsWith('notification'))) {
    allowedEditors.add('notifications');
  }

  // Carpools related roles
  if (roles.some(role => role.startsWith('carpools'))) {
    allowedEditors.add('carpools');
  }

  return Array.from(allowedEditors);
}; 