import React, { useEffect, useState } from 'react';
import { CarpoolReport, ReportStatus } from '../../../model/Report';
import { fetchReports, ignoreReport, applyReport } from '../../../services/api';
import Modal from '../../../components/Modal';
import './ReportingEditor.css';

interface ReportingEditorProps {
  sessionToken: string;
  festivalId: string;
}

interface ActionModalProps {
  report: CarpoolReport;
  action: 'ignore' | 'delete';
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

const ActionModal: React.FC<ActionModalProps> = ({ report, action, onConfirm, onCancel }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleConfirm = async () => {
    setIsProcessing(true);
    setError(null);
    try {
      await onConfirm();
      onCancel();
    } catch (err) {
      setError(err instanceof Error ? err.message : `Failed to ${action} report`);
      setIsProcessing(false);
    }
  };

  const actionText = action === 'ignore' ? 'Ignore' : 'Delete';

  return (
    <Modal isOpen={true} onClose={() => !isProcessing && onCancel()}>
      <div className="modal-header">
        <h3>{actionText} Report</h3>
        {!isProcessing && (
          <button className="modal-close" onClick={onCancel}>×</button>
        )}
      </div>
      {error && <div className="modal-error">{error}</div>}
      <p>
        {action === 'ignore' 
          ? `Are you sure you want to ignore this report for carpool from ${report.carpool.user_name}?`
          : `Are you sure you want to delete this carpool from ${report.carpool.user_name}?`
        }
      </p>
      <div className="modal-actions">
        <button 
          className={`modal-${action}`}
          onClick={handleConfirm}
          disabled={isProcessing}
        >
          {isProcessing ? `${actionText}ing...` : actionText}
        </button>
        <button 
          className="modal-cancel"
          onClick={onCancel}
          disabled={isProcessing}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

const ReportingEditor: React.FC<ReportingEditorProps> = ({ sessionToken, festivalId }) => {
  const [reports, setReports] = useState<CarpoolReport[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedReport, setSelectedReport] = useState<{ report: CarpoolReport; action: 'ignore' | 'delete' } | null>(null);

  const loadReports = async () => {
    try {
      setIsLoading(true);
      const data = await fetchReports(sessionToken, festivalId);
      setReports(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load reports');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadReports();
  }, [sessionToken, festivalId]);

  const handleIgnore = async (reportId: string) => {
    await ignoreReport(sessionToken, festivalId, reportId);
    await loadReports();
  };

  const handleDelete = async (reportId: string) => {
    await applyReport(sessionToken, festivalId, reportId);
    await loadReports();
  };

  if (isLoading) {
    return <div>Loading reports...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  };

  const getStatusClass = (status: ReportStatus) => {
    return `status-badge ${status}`;
  };

  return (
    <div className="reporting-editor">
      <h2>Reports</h2>
      <div className="reports-table-container">
        <table className="reports-table">
          <thead>
            <tr>
              <th>Created</th>
              <th>Status</th>
              <th>Reporter ID</th>
              <th>Carpool Info</th>
              <th>User Info</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reports.map(reportItem => (
              <tr key={reportItem.report.identifier}>
                <td>{formatDate(reportItem.report.created_at)}</td>
                <td>
                  <span className={getStatusClass(reportItem.report.status)}>
                    {reportItem.report.status}
                  </span>
                </td>
                <td>{reportItem.report.reported_by_device_id}</td>
                <td>
                  <div>Direction: {reportItem.carpool.direction}</div>
                  <div>Departure: {formatDate(reportItem.carpool.departure_date)}</div>
                </td>
                <td>
                  <div>Name: {reportItem.carpool.user_name}</div>
                  <div>Phone: {reportItem.carpool.user_phone}</div>
                </td>
                <td>
                  {reportItem.report.status === 'pending' && (
                    <div className="action-buttons">
                      <button
                        className="ignore-button"
                        onClick={() => setSelectedReport({ report: reportItem, action: 'ignore' })}
                      >
                        Ignore
                      </button>
                      <button
                        className="delete-button"
                        onClick={() => setSelectedReport({ report: reportItem, action: 'delete' })}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedReport && (
        <ActionModal
          report={selectedReport.report}
          action={selectedReport.action}
          onConfirm={() => 
            selectedReport.action === 'ignore' 
              ? handleIgnore(selectedReport.report.report.identifier)
              : handleDelete(selectedReport.report.report.identifier)
          }
          onCancel={() => setSelectedReport(null)}
        />
      )}
    </div>
  );
};

export default ReportingEditor; 