import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FestivalData, POI, ImageData, Map } from '../../../model/FestivalData';
import ImageEditor from './ImageEditor';
import { v4 as uuidv4 } from 'uuid';
import { generateCanonicalName, generateIdentifier } from '../../../utils/identifiers';
import { getImageUrl } from '../../../services/imageService';
import Modal from '../../../components/Modal';

interface PoisEditorProps {
  pois: POI[];
  onChange: (newPois: POI[]) => void;
  getApiImageUrl: (identifier: string) => string;
  maps: Map[];
}

const PoisEditor: React.FC<PoisEditorProps> = ({ pois, onChange, getApiImageUrl, maps }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [editingPoi, setEditingPoi] = useState<POI | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<Partial<POI> | null>(null);
  const [tempImages, setTempImages] = useState<Record<string, { icon?: string; main_image?: string }>>({});
  const [selectedMapId, setSelectedMapId] = useState<string | null>(maps[0]?.identifier || null);

  const filteredPois = pois.filter(poi => {
    const matchesSearch = poi.name.toLowerCase().includes(searchTerm.toLowerCase());
    if (selectedMapId === null) {
      return matchesSearch && (!poi.map_identifier || poi.map_identifier === '' || !maps.find(m => m.identifier === poi.map_identifier));
    }
    return matchesSearch && poi.map_identifier === selectedMapId;
  });

  const unsavedPoiId = "unsaved_poi_id";

  const handleAddPoi = () => {
    const newPoi: POI = {
      category: 'scene',
      identifier: unsavedPoiId,
      name: 'New Place',
      map_identifier: selectedMapId || '',
      relative_coordinates: { x: 0.5, y: 0.5 },
      icon: null,
      main_image: null,
      description: ''
    };
    setEditingPoi(newPoi);
    setUnsavedChanges(null);
    setTempImages({});
  };

  const handleEditPoi = (poi: POI) => {
    setEditingPoi({...poi});
    setUnsavedChanges(null);
    setTempImages({});
  };

  const handleSavePoi = useCallback((poiToSave: POI) => {
    const canonicalName = generateCanonicalName(poiToSave.name);
    
    const newIdentifier = poiToSave.identifier === unsavedPoiId
      ? generateIdentifier(canonicalName)
      : poiToSave.identifier;
    
    const updatedPoi: POI = { 
      ...poiToSave,
      ...unsavedChanges,
      identifier: newIdentifier
    };
    
    const updatedPois = pois.map(poi => 
      poi.identifier === poiToSave.identifier ? updatedPoi : poi
    );

    if (!updatedPois.some(poi => poi.identifier === poiToSave.identifier)) {
      updatedPois.push(updatedPoi);
    }

    if (tempImages[poiToSave.identifier]) {
      setTempImages(prev => ({
        ...prev,
        [newIdentifier]: prev[poiToSave.identifier]
      }));
    }

    onChange(updatedPois);
    setEditingPoi(null);
    setUnsavedChanges(null);
  }, [pois, onChange, unsavedChanges, tempImages]);

  const handleRemovePoi = (identifier: string) => {
    const poiToRemove = pois.find(poi => poi.identifier === identifier);
    if (poiToRemove && window.confirm(`Are you sure you want to delete the POI "${poiToRemove.name}"?`)) {
      const updatedPois = pois.filter(poi => poi.identifier !== identifier);
      onChange(updatedPois);
      setEditingPoi(null);
      setUnsavedChanges(null);
      setTempImages(prev => {
        const { [identifier]: removed, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleImageChange = (imageType: 'icon' | 'main_image', newImage: ImageData | undefined) => {
    if (editingPoi) {
      setUnsavedChanges(prev => ({
        ...prev,
        [imageType]: newImage || null
      }));

      if (newImage && newImage.type === 'ApiImage' && 'file' in newImage && newImage.file) {
        setTempImages(prev => ({
          ...prev,
          [editingPoi.identifier]: {
            ...prev[editingPoi.identifier],
            [imageType]: URL.createObjectURL(newImage.file as Blob)
          }
        }));
      } else {
        setTempImages(prev => {
          const updated = { ...prev };
          if (updated[editingPoi.identifier]) {
            delete updated[editingPoi.identifier][imageType as keyof typeof updated[typeof editingPoi.identifier]];
            if (Object.keys(updated[editingPoi.identifier]).length === 0) {
              delete updated[editingPoi.identifier];
            }
          }
          return updated;
        });
      }
    }
  };

  const getPoiImageSrc = (poi: POI, imageType: 'icon' | 'main_image'): string | undefined => {
    if (tempImages[poi.identifier]?.[imageType]) {
      return tempImages[poi.identifier][imageType];
    }
    return getImageUrl(poi[imageType], getApiImageUrl);
  };

  const getPoiPosition = (poi: POI) => {
    const { x, y } = poi.relative_coordinates;
    
    if (x < 0 || x > 1 || y < 0 || y > 1) return null;

    return { x: x * 100, y: y * 100 };
  };

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const draggingPoiRef = useRef<string | null>(null);

  const handlePoiDragStart = (e: React.MouseEvent, poiId: string) => {
    e.stopPropagation();
    draggingPoiRef.current = poiId;
  };

  const handlePoiDragEnd = () => {
    draggingPoiRef.current = null;
  };

  // Add these refs to track map image dimensions
  const mapImageRef = useRef<HTMLImageElement>(null);
  const mapImageBoundsRef = useRef<{ width: number; height: number; left: number; top: number }>({ width: 0, height: 0, left: 0, top: 0 });

  // Add this effect to update map bounds when image loads or container resizes
  useEffect(() => {
    const updateMapBounds = () => {
      const img = mapImageRef.current;
      if (img) {
        const rect = img.getBoundingClientRect();
        mapImageBoundsRef.current = {
          width: rect.width,
          height: rect.height,
          left: rect.left,
          top: rect.top
        };
      }
    };

    updateMapBounds();
    window.addEventListener('resize', updateMapBounds);
    return () => window.removeEventListener('resize', updateMapBounds);
  }, []);

  // Update handleMapClick to calculate coordinates relative to the wrapper
  const handleMapClick = (e: React.MouseEvent) => {
    const wrapper = mapImageRef.current?.parentElement;
    if (!wrapper) return;

    const rect = wrapper.getBoundingClientRect();
    const x = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
    const y = Math.max(0, Math.min(1, (e.clientY - rect.top) / rect.height));

    if (draggingPoiRef.current) {
      const updatedPois = pois.map(poi => {
        if (poi.identifier === draggingPoiRef.current) {
          return {
            ...poi,
            relative_coordinates: { x, y }
          };
        }
        return poi;
      });
      onChange(updatedPois);
    }
  };

  const handleMapImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const img = e.currentTarget;
    const aspectRatio = (img.naturalHeight / img.naturalWidth) * 100;
    img.parentElement!.style.paddingTop = `${aspectRatio}%`;
    
    const rect = img.getBoundingClientRect();
    mapImageBoundsRef.current = {
      width: rect.width,
      height: rect.height,
      left: rect.left,
      top: rect.top
    };
  };

  const renderPoiMarker = (poi: POI) => {
    const position = getPoiPosition(poi);
    if (!position) return null;

    return (
      <div 
        key={poi.identifier}
        style={{
          position: 'absolute',
          left: `${position.x}%`,
          top: `${position.y}%`,
          transform: 'translate(-50%, -50%)',
          width: '20px',
          height: '20px',
          borderRadius: '50%',
          backgroundColor: 'red',
          cursor: draggingPoiRef.current === poi.identifier ? 'grabbing' : 'grab',
          zIndex: draggingPoiRef.current === poi.identifier ? 1000 : 1
        }}
        onMouseDown={(e) => handlePoiDragStart(e, poi.identifier)}
        onMouseUp={handlePoiDragEnd}
        onClick={(e) => {
          e.stopPropagation();
          if (!draggingPoiRef.current) {
            handleEditPoi(poi);
          }
        }}
        title={poi.name}
      />
    );
  };

  const renderMapTabs = () => (
    <div className="map-tabs">
      {maps.map(map => (
        <button
          key={map.identifier}
          className={`map-tab ${selectedMapId === map.identifier ? 'active' : ''}`}
          onClick={() => setSelectedMapId(map.identifier)}
        >
          {map.name || 'Untitled Map'}
        </button>
      ))}
      <button
        className={`map-tab ${selectedMapId === null ? 'active' : ''}`}
        onClick={() => setSelectedMapId(null)}
      >
        Unattributed POIs
      </button>
    </div>
  );

  const renderMapSection = () => {
    if (!maps || !maps.length) {
      return (
        <div className="map-placeholder">
          <p>No map configured. A map is needed to place POIs.</p>
          <button 
            onClick={() => window.dispatchEvent(new CustomEvent('switchTab', { detail: 'map' }))}
            className="primary-button"
          >
            Configure Map
          </button>
        </div>
      );
    }

    const selectedMap = selectedMapId ? maps.find(m => m.identifier === selectedMapId) : null;

    return (
      <>
        {renderMapTabs()}
        {selectedMap ? (
          <div 
            ref={mapContainerRef}
            className="map-container" 
            style={{ 
              position: 'relative', 
              marginBottom: '20px',
              width: '80%',
              margin: '0 auto',
              backgroundColor: '#f5f5f5',
              overflow: 'hidden',
            }}
            onClick={handleMapClick}
            onMouseMove={(e) => draggingPoiRef.current && handleMapClick(e)}
          >
            <div style={{
              position: 'relative',
              width: '100%',
              paddingTop: '75%',
            }}>
              <img 
                ref={mapImageRef}
                src={getImageUrl(selectedMap.image, getApiImageUrl)}
                alt="Festival Map" 
                style={{ 
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain'
                }}
                onLoad={handleMapImageLoad}
                draggable={false}
              />
              {filteredPois.map(poi => renderPoiMarker(poi))}
            </div>
          </div>
        ) : (
          <div className="unattributed-section">
            <p>POIs without assigned maps or with invalid map references will appear here. Assign a map to a POI by editing it.</p>
          </div>
        )}
      </>
    );
  };

  // Add this effect to clean up URLs
  useEffect(() => {
    return () => {
      // Clean up any object URLs when component unmounts
      Object.values(tempImages).forEach(images => {
        if (images.icon) URL.revokeObjectURL(images.icon);
        if (images.main_image) URL.revokeObjectURL(images.main_image);
      });
    };
  }, [tempImages]);

  // Add ref for modal content
  const modalRef = useRef<HTMLDivElement>(null);

  // Add useClickOutside hook
  const handleClickOutside = (e: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      setEditingPoi(null);
      setUnsavedChanges(null);
      setTempImages({});
    }
  };

  useEffect(() => {
    if (editingPoi) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [editingPoi]);

  const renderPoiEditModal = () => {
    if (!editingPoi) return null;

    return (
      <div className="modal-overlay">
        <div className="modal-content" ref={modalRef}>
          <h3>{editingPoi.identifier === unsavedPoiId ? 'Add Place' : 'Edit Place'}</h3>
          
          <div className="form-field">
            <label>Map</label>
            <select
              value={editingPoi.map_identifier}
              onChange={(e) => setEditingPoi({ 
                ...editingPoi, 
                map_identifier: e.target.value 
              })}
            >
              <option key="" value="">
                  Unattributed
                </option>
              {maps.map(map => (
                <option key={map.identifier} value={map.identifier}>
                  {map.name || 'Untitled Map'}
                </option>
              ))}
            </select>
          </div>

          <input
            type="text"
            value={editingPoi.name}
            onChange={(e) => setEditingPoi({ ...editingPoi, name: e.target.value })}
            placeholder="POI Name"
          />
          <select
            value={editingPoi.category}
            onChange={(e) => setEditingPoi({ ...editingPoi, category: e.target.value as 'scene' | 'food' })}
          >
            <option value="scene">Scene</option>
            <option value="food">Food</option>
          </select>
          <div className="coordinates">
            <input
              type="number"
              value={editingPoi.relative_coordinates.x}
              onChange={(e) => setEditingPoi({ 
                ...editingPoi, 
                relative_coordinates: { 
                  ...editingPoi.relative_coordinates, 
                  x: Math.max(0, Math.min(1, parseFloat(e.target.value))) 
                }
              })}
              placeholder="X Position (0-1)"
              step="0.01"
              min="0"
              max="1"
            />
            <input
              type="number"
              value={editingPoi.relative_coordinates.y}
              onChange={(e) => setEditingPoi({ 
                ...editingPoi, 
                relative_coordinates: { 
                  ...editingPoi.relative_coordinates, 
                  y: Math.max(0, Math.min(1, parseFloat(e.target.value))) 
                }
              })}
              placeholder="Y Position (0-1)"
              step="0.01"
              min="0"
              max="1"
            />
          </div>
          <textarea
            value={editingPoi.description}
            onChange={(e) => setEditingPoi({ ...editingPoi, description: e.target.value })}
            placeholder="POI Description"
          />
          <h4>Icon</h4>
          <ImageEditor
            currentImage={(unsavedChanges?.icon as ImageData) || editingPoi.icon || undefined}
            onChange={(newImage) => handleImageChange('icon', newImage)}
            getApiImageUrl={getApiImageUrl}
          />
          <h4>Main Image</h4>
          <ImageEditor
            currentImage={(unsavedChanges?.main_image as ImageData) || editingPoi.main_image || undefined}
            onChange={(newImage) => handleImageChange('main_image', newImage)}
            getApiImageUrl={getApiImageUrl}
          />
          <div className="modal-actions">
            <button onClick={() => handleSavePoi(editingPoi)}>Save</button>
            <button onClick={() => {
              setEditingPoi(null);
              setUnsavedChanges(null);
              setTempImages({});
            }}>Cancel</button>
            {editingPoi.identifier && (
              <button onClick={() => handleRemovePoi(editingPoi.identifier)}>Remove</button>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="pois-editor">
      <h2>Points of Interest</h2>
      {renderMapSection()}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search POIs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="pois-grid">
        {filteredPois.map(poi => (
          <div key={poi.identifier} className="poi-card" onClick={() => handleEditPoi(poi)}>
            {getPoiImageSrc(poi, 'main_image') && (
              <img 
                src={getPoiImageSrc(poi, 'main_image')}
                alt={poi.name}
                className="card-image"
              />
            )}
            <div className="card-content">
              <h3 className="card-title">{poi.name}</h3>
              <p className="card-subtitle">{poi.category}</p>
            </div>
          </div>
        ))}
        <div className="poi-card add-card" onClick={handleAddPoi}>
          <span>+</span>
          <h3>Add Place</h3>
        </div>
      </div>
      {renderPoiEditModal()}
    </section>
  );
};

export default PoisEditor;
