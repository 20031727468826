import React, { useState, useRef, useEffect } from 'react';
import { Show, Artist } from '../../../model/FestivalData';
import { sendNotification } from '../../../services/api';

interface NotificationsEditorProps {
  shows: Show[];
  artists: Artist[];
  sessionToken: string;
  festivalId: string;
}

interface NotificationForm {
  showId: string;
  title: string;
  body: string;
}

interface DropdownOption {
  value: string;
  label: string;
  group?: string;
}

interface DropdownSection {
  label: string;
  options: DropdownOption[];
}

const all_users_push_topic = 'all_users';

const NotificationsEditor: React.FC<NotificationsEditorProps> = ({
  shows,
  artists,
  sessionToken,
  festivalId,
}) => {
  const [form, setForm] = useState<NotificationForm>({
    showId: '',
    title: '',
    body: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [recipientFilter, setRecipientFilter] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getArtistName = (artistId: string): string => {
    return artists.find(artist => artist.identifier === artistId)?.name || 'Unknown Artist';
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleSend = async () => {
    try {
      await sendNotification(sessionToken, festivalId, {
        topic: form.showId === all_users_push_topic ? all_users_push_topic : `show_${form.showId}`,
        title: form.title,
        body: form.body,
      });
      
      setShowModal(false);
      setIsSuccess(true);
      setForm({ showId: '', title: '', body: '' });
      
      // Hide success message after 3 seconds
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Failed to send notification:', error);
      alert('Failed to send notification. Please try again.');
    }
  };

  const sections: DropdownSection[] = [
    {
      label: "General",
      options: [
        { value: all_users_push_topic, label: 'All users' }
      ]
    },
    {
      label: "Show Subscribers",
      options: shows.map(show => ({
        value: show.identifier,
        label: getArtistName(show.artist_id)
      }))
    }
  ];

  const filteredSections = sections.map(section => ({
    ...section,
    options: section.options.filter(option =>
      option.label.toLowerCase().includes(recipientFilter.toLowerCase())
    )
  })).filter(section => section.options.length > 0);

  const selectedOption = sections
    .flatMap(section => section.options)
    .find(opt => opt.value === form.showId) || { value: '', label: 'Select recipients' };

  // Add keyboard navigation
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsDropdownOpen(false);
    }
  };

  // Prevent dropdown close when clicking filter
  const handleFilterClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  // Focus filter input when dropdown opens
  useEffect(() => {
    if (isDropdownOpen) {
      const filterInput = document.querySelector('.recipient-filter') as HTMLInputElement;
      if (filterInput) {
        filterInput.focus();
      }
    }
  }, [isDropdownOpen]);

  return (
    <div className="notifications-editor">
      <h2>Send Notifications</h2>
      
      {isSuccess && (
        <div className="success-alert">
          Notification sent successfully!
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="showId">Recipients</label>
          <div className="custom-dropdown" ref={dropdownRef}>
            <div 
              className="dropdown-header" 
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              role="combobox"
              aria-expanded={isDropdownOpen}
              aria-haspopup="listbox"
              tabIndex={0}
              onKeyDown={handleKeyDown}
            >
              <span>{selectedOption.label}</span>
              <span className={`dropdown-arrow ${isDropdownOpen ? 'open' : ''}`}>▼</span>
            </div>
            
            {isDropdownOpen && (
              <div className="dropdown-content">
                <div className="filter-container">
                  <input
                    type="text"
                    placeholder="Type to search recipients..."
                    value={recipientFilter}
                    onChange={(e) => setRecipientFilter(e.target.value)}
                    className="recipient-filter"
                    onClick={handleFilterClick}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div 
                  className="dropdown-options"
                  role="listbox"
                >
                  {filteredSections.map((section, index) => (
                    <div key={section.label} className="dropdown-section">
                      <div className="dropdown-section-header">
                        {section.label}
                        <span className="section-count">
                          {section.options.length}
                        </span>
                      </div>
                      {section.options.map((option) => (
                        <div
                          key={option.value}
                          className={`dropdown-option ${form.showId === option.value ? 'selected' : ''}`}
                          onClick={() => {
                            setForm({ ...form, showId: option.value });
                            setIsDropdownOpen(false);
                            setRecipientFilter('');
                          }}
                          role="option"
                          aria-selected={form.showId === option.value}
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  ))}
                  {filteredSections.length === 0 && (
                    <div className="dropdown-no-results">
                      No recipients match "{recipientFilter}"
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
            required
            maxLength={50}
          />
        </div>

        <div className="form-group">
          <label htmlFor="body">Message</label>
          <textarea
            id="body"
            value={form.body}
            onChange={(e) => setForm({ ...form, body: e.target.value })}
            required
            maxLength={200}
            rows={4}
          />
        </div>

        <button type="submit" className="send-button">
          Send Notification
        </button>
      </form>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Confirm Notification</h3>
            <div className="notification-preview">
              <p><strong>Recipients:</strong> {
                form.showId === all_users_push_topic 
                  ? 'All users' 
                  : `Subscribers of ${getArtistName(shows.find(s => s.identifier === form.showId)?.artist_id || '')}`
              }</p>
              <p><strong>Title:</strong> {form.title}</p>
              <p><strong>Message:</strong> {form.body}</p>
            </div>
            <div className="modal-actions">
              <button onClick={handleSend} className="primary">
                Send
              </button>
              <button onClick={() => setShowModal(false)} className="secondary">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsEditor; 