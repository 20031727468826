import React, { useState } from 'react';
import './CreateFestival.css';
import { createFestival } from '../../services/api';

interface CreateFestivalProps {
  onBack: () => void;
  onFestivalCreated: (festivalId: string, adminEmail: string) => void;
}

const CreateFestival: React.FC<CreateFestivalProps> = ({ onBack, onFestivalCreated }) => {
  const [festivalName, setFestivalName] = useState('');
  const [masterKey, setMasterKey] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [firebaseSecretKey, setFirebaseSecretKey] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!firebaseSecretKey) {
      setError('Firebase Secret Key is required');
      return;
    }

    let parsedFirebaseSecretKey;
    try {
      parsedFirebaseSecretKey = JSON.parse(firebaseSecretKey);
    } catch (err) {
      setError('Firebase Secret Key must be a valid JSON');
      return;
    }

    if (!subdomain.match(/^[a-z0-9-]+$/)) {
      setError('Subdomain can only contain lowercase letters, numbers, and hyphens');
      return;
    }

    try {
      const { festival_id } = await createFestival(
        masterKey,
        festivalName,
        adminEmail,
        parsedFirebaseSecretKey,
        subdomain
      );

      console.log('Festival creation successful. Festival ID:', festival_id);
      onFestivalCreated(festival_id, adminEmail);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    }
  };

  return (
    <div className="create-festival-container">
      <div className="create-festival-form-wrapper">
        <h2>Create Festival</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit} className="create-festival-form">
          <div className="form-group">
            <label htmlFor="festival-name">Festival Name:</label>
            <input
              type="text"
              id="festival-name"
              value={festivalName}
              onChange={(e) => setFestivalName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="master-key">Master Key:</label>
            <input
              type="password"
              id="master-key"
              value={masterKey}
              onChange={(e) => setMasterKey(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="admin-email">Admin Email:</label>
            <input
              type="email"
              id="admin-email"
              value={adminEmail}
              onChange={(e) => setAdminEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subdomain">Subdomain:</label>
            <input
              type="text"
              id="subdomain"
              value={subdomain}
              onChange={(e) => setSubdomain(e.target.value.toLowerCase())}
              placeholder="e.g., myfestival"
              pattern="[a-z0-9]+"
              title="Only lowercase letters and numbers are allowed"
              required
            />
            <small className="help-text">
              This will be used as your festival's subdomain (e.g., my-festival.yourdomain.com)
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="firebase-secret-key">Firebase Secret Key (JSON):</label>
            <textarea
              id="firebase-secret-key"
              value={firebaseSecretKey}
              onChange={(e) => setFirebaseSecretKey(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="create-festival-btn">Create Festival</button>
          <button type="button" onClick={onBack} className="back-btn">
            Back to Admin
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateFestival;
