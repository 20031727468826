import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './VersionList.css';
import { FestivalData } from '../../model/FestivalData';
import { fetchVersions, updateFestivalData } from '../../services/api';

interface VersionListProps {
  sessionToken: string;
  festivalId: string;
  onLogout: () => void;
}

const VersionList: React.FC<VersionListProps> = ({ sessionToken, festivalId, onLogout }) => {
  const [versions, setVersions] = useState<FestivalData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingDraft, setIsCreatingDraft] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const loadVersions = async () => {
      try {
        setIsLoading(true);
        const fetchedVersions = await fetchVersions(sessionToken, festivalId);
        setVersions(fetchedVersions);
      } catch (err) {
        console.error('Error fetching versions:', err);
        setError(err instanceof Error ? err.message : 'An error occurred while fetching versions');
      } finally {
        setIsLoading(false);
      }
    };

    loadVersions();
  }, [sessionToken, festivalId]);

  const handleCreateNewDraft = async () => {
    if (versions.length === 0) {
      setError('Cannot create new draft: no previous version found');
      return;
    }

    try {
      setIsCreatingDraft(true);
      setError(null);

      // Get the latest version
      const sortedVersions = [...versions].sort((a, b) => b.version - a.version);
      const latestVersion = sortedVersions[0];

      // Create new version data
      const newVersion: FestivalData = {
        ...latestVersion,
        version: latestVersion.version + 1,
        status: 'draft',
        updated_at: new Date().toISOString()
      };

      // Save the new version to server
      await updateFestivalData(sessionToken, festivalId, newVersion.version.toString(), newVersion);
      
      // Navigate to editor
      history.push(`/editor/${newVersion.version}`);
    } catch (err) {
      console.error('Error creating new version:', err);
      setError(err instanceof Error ? err.message : 'Failed to create new version');
    } finally {
      setIsCreatingDraft(false);
    }
  };

  return (
    <div className="VersionList">
      <div className="header">
        <h1>Festival Versions</h1>
        <button onClick={onLogout} className="logout-btn">Logout</button>
      </div>
      <div className="content">
        {error && <p className="error">{error}</p>}
        <button 
          onClick={handleCreateNewDraft} 
          className="new-draft-btn"
          disabled={isCreatingDraft}
        >
          {isCreatingDraft ? 'Creating Draft...' : 'Create New Draft'}
        </button>
        {isLoading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : versions.length > 0 ? (
          <ul className="version-list">
            {versions.map((version) => (
              <li key={version.version}>
                <Link 
                  to={{
                    pathname: `/editor/${version.version}`,
                    state: { versionData: version }
                  }} 
                  className="version-link"
                >
                  <span className="version-number">Version {version.version}</span>
                  <span className={`status-pill ${version.status.toLowerCase()}`}>
                    {version.status}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>No versions found.</p>
        )}
      </div>
    </div>
  );
};

export default VersionList;
